import { ActivityStore, ActivityTrackingItem, ActivityTrackingStore, TimeframeStore } from '@stores';
import apiResponse from '@stores/ApiResponse';
import { ActivityHistoryItem, ActivityHistoryView, Lookup } from '@views';
import { DateTime, Duration } from 'luxon';
import { useQuery } from '@tanstack/react-query';

export class ActivityHistoryViewCreator {
  constructor(
    private readonly store: ActivityTrackingStore = new ActivityTrackingStore(),
    private readonly activityStore: ActivityStore = new ActivityStore(),
    private readonly timeframeStore: TimeframeStore = new TimeframeStore()
  ) {}

  public async createView(cohortUserId: number) {
    const [activities, timeframes, response] = await Promise.all([
      apiResponse.handleApiResponse(() => this.activityStore.readAll()),
      apiResponse.handleApiResponse(() => this.timeframeStore.readAll()),
      apiResponse.handleApiResponse(() => this.store.readAllByCohortUser(cohortUserId)),
    ]);

    return {
      items: response.map((x: ActivityTrackingItem, idx: number) => {
        return {
          id: idx,
          activity: this.findLookupString(x.activityId, activities).name,
          name: x.name,
          date: DateTime.fromISO(String(x.dateStamp)).toFormat('LLL dd yyyy'),
          duration: x.duration,
          timeframe: this.findLookupString(x.timeframeId, timeframes).name,
        } as ActivityHistoryItem;
      }),
    } as ActivityHistoryView;
  }

  private findLookupString(id: number, values: Lookup[]) {
    const found = values.find((x) => x.id === id);
    if (!found) {
      return { id: -1, name: '' };
    }
    return found;
  }
}

export const useActivityHistoryViewCreator = (cohortUserId: number) =>
  useQuery({
    queryKey: ['activityHistoryViewCreator', cohortUserId],
    queryFn: () => new ActivityHistoryViewCreator().createView(cohortUserId),
    staleTime: Duration.fromObject({ minutes: 1 }).milliseconds,
  });
