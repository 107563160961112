// viewCreators/SurveyViewCreator.ts
import apiResponse from '../api/ApiResponse';
import { SurveyStore } from '../api/SurveyStore';
import { CohortSurvey } from '../models/CohortSurvey';
import { DateTime } from 'luxon';

export class CohortSurveyViewCreator {
  constructor(private readonly store: SurveyStore = new SurveyStore()) {}

  public async CreateView(surveyId: number, groupId: number): Promise<CohortSurveyView> {
    // const response = await fetch(`/api/surveys/${surveyId}`);
    const sections = await apiResponse.handleApiResponse<CohortSurvey[]>(() =>
      this.store.ReadAllCohortSurveys(groupId, surveyId)
    );
    const result = sections.reduce<CohortSurveyView>(
      (acc: CohortSurveyView, item: CohortSurvey) => {
        if (!acc.surveyId) {
          acc.surveyId = item.surveyId;
          acc.cohortId = item.cohortId;
          acc.sections = [];
        }

        const availableDate =
          item.availableDate && !DateTime.isDateTime(item.availableDate)
            ? DateTime.fromISO(item.availableDate)
            : item.availableDate;
        acc.sections.push({
          cohortSurveyId: item.id,
          availableDate: availableDate,
          sectionId: item.sectionId,
          title: item.sectionName,
        });
        return acc;
      },
      { surveyId: 0, cohortId: 0, sections: [] }
    );
    return result;
  }
}

export type CohortSurveyView = {
  surveyId: number;
  cohortId: number;
  sections: {
    cohortSurveyId: number;
    sectionId: number;
    title: string;
    availableDate: DateTime;
  }[];
};
