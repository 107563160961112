import apiResponse from '../api/ApiResponse';
import { SurveyStore } from '../api/SurveyStore';

export type SurveySummaryView = {
  views: SurveyView[];
};

export type SurveyView = {
  id: number;
  name: string;
};

export class SurveySummaryViewCreator {
  constructor(private readonly store: SurveyStore = new SurveyStore()) {}

  public async createView(): Promise<SurveySummaryView> {
    const surveys = await apiResponse.handleApiResponse(() => this.store.ReadAll());
    return {
      views: surveys.map((m: any) => {
        return { id: m.id, name: m.title };
      }),
    };
  }
}
