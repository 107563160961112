import { DateTime, IANAZone, SystemZone } from 'luxon';

export const convertStringDateToIsoString = (dateString: string): string => {
  let dt: DateTime;

  // Attempt various parsing formats explicitly
  dt = DateTime.fromFormat(dateString, "MMMM d, yyyy HH:mm:ss 'GMT'ZZ", {
    setZone: true,
  });

  if (!dt.isValid) {
    dt = DateTime.fromISO(dateString, { setZone: true });
  }
  if (!dt.isValid) {
    dt = DateTime.fromRFC2822(dateString, { setZone: true });
  }
  if (!dt.isValid) {
    dt = DateTime.fromFormat(dateString, 'M/d/yyyy HH:mm:ss z', {
      setZone: true,
    });
  }
  if (!dt.isValid) {
    dt = DateTime.fromFormat(dateString, 'M/d/yyyy', { zone: 'UTC' });
  }
  if (!dt.isValid) {
    // If the string doesn't contain a timezone, force UTC
    dt = DateTime.fromFormat(dateString, 'LLL d yyyy hh:mm a', { zone: 'UTC' });
  }
  if (!dt.isValid) {
    // Final fallback to JS Date object with UTC assumption
    const date = new Date(dateString);

    dt = DateTime.fromISO(date.toISOString(), { setZone: false });
  }

  if (!dt.isValid) {
    throw new Error(`Invalid date string: ${dateString}`);
  }

  // Return the ISO string
  return String(dt.toISO());
};

/**
 * Converts the provided date argument into a DateTime object.
 *
 * This function supports multiple date input formats, including ISO date strings, `DateTime` objects, and native `Date` objects.
 *
 * If the input is a string, it will be converted to an ISO-compliant string and then transformed into a `DateTime` object.
 * If the input is a `Date` object, it will optionally consider the absolute (local) or UTC date values based on the `useAbsolute` parameter.
 * If the input is already a `DateTime` object, it will return the input as is.
 *
 * The function provides flexibility to handle time zones effectively when working with dates, ensuring proper transitions between local and UTC times when necessary.
 *
 * @param {string | DateTime | Date} date - The date input to be converted. This can either be a string (ISO format or similar), a `DateTime` object, or a native `Date` object.
 * @param {boolean} [useAbsolute=false] - A flag to determine whether the conversion should use absolute (local) date values or UTC dates. Defaults to `false`.
 * @returns {DateTime} The resulting DateTime object created from the input.
 *
 * @example
 * const dateString = '2000-01-01T17:01:00T-07:00';
 * const date = new Date(dateString);
 * const dateTime = ToDate(date) // will return 2000-01-02 since 17:01:00-0700 = 00:01:00 + 1 day in UTC
 * const dateTime = ToDate(date, true) // will return 2000-01-01 since timezone offset is ignored
 */
export const ToDate = (date: string | DateTime | Date, useAbsolute: boolean = false): DateTime => {
  if (typeof date === 'string') {
    const convertedDateString = convertStringDateToIsoString(date);
    return DateTime.fromISO(convertedDateString, { setZone: true });
  }

  if (date instanceof Date) {
    const year = useAbsolute ? date.getFullYear() : date.getUTCFullYear();
    const day = useAbsolute ? date.getDate() : date.getUTCDate();
    const month = useAbsolute ? date.getMonth() + 1 : date.getUTCMonth() + 1; // JS date months are 0-indexed

    const dateTime = DateTime.fromObject({ year, month, day }).startOf('day');
    const zoneName = DateTime.fromJSDate(date).zoneName ?? 'UTC';
    const zone = IANAZone.create(zoneName);
    if (useAbsolute) {
      dateTime.setZone(zone, { keepLocalTime: true });
    }
    return dateTime;
  }
  return date;
};

export const ToDateTime = (date: string | DateTime | Date): DateTime => {
  if (typeof date === 'string') {
    // Strip the timezone information and interpret the date/time as local
    const dateWithoutZone = date.substring(0, 19); // Strip everything after the seconds part
    return DateTime.fromISO(dateWithoutZone, { zone: 'UTC' });
  } else if (date instanceof Date) {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // JS months are 0-indexed
    const day = date.getUTCDate();

    // Manually construct the DateTime object and add the timezone offset to adjust it
    return DateTime.utc(year, month, day);
  } else if (date instanceof DateTime) {
    // If it's already a Luxon DateTime, just return it
    return date;
  }

  throw new Error('Invalid date type');
};

export const MAX_DATE = DateTime.fromISO('9999-12-31');
