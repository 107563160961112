import { ActivityStore, apiResponse, TimeframeStore } from '@stores';
import { ActivityLookupView } from '@views';
import { useQuery } from '@tanstack/react-query';
import { Duration } from 'luxon';

export class ActivityLookupViewCreator {
  constructor(
    private readonly activityStore: ActivityStore = new ActivityStore(),
    private readonly timeframeStore: TimeframeStore = new TimeframeStore()
  ) {}

  public async createView(): Promise<ActivityLookupView> {
    const [activities, timeframes] = await Promise.all([
      apiResponse.handleApiResponse(() => this.activityStore.readAll()),
      apiResponse.handleApiResponse(() => this.timeframeStore.readAll()),
    ]);

    return {
      activities,
      timeframes,
    };
  }
}

export const useActivityLookupViewCreator = () =>
  useQuery({
    queryKey: ['activityLookupViewCreator'],
    queryFn: () => new ActivityLookupViewCreator().createView(),
    staleTime: Duration.fromObject({ minutes: 10 }).milliseconds,
  });
