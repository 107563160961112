import { CohortUser } from '@models/CohortUser';
import { RoleStrings } from '@app/enums/Role';

export interface ParticipantView {
  id: number;
  name: string;
  email: string;
  avatarUrl: string;
  role: string;
  isNew: boolean;
  CohortUser: CohortUser;
}

const empty: ParticipantView = {
  id: 0,
  name: '',
  email: '',
  avatarUrl: '',
  role: RoleStrings.Participant,
  isNew: false,
  CohortUser: {
    id: 0,
    userId: 0,
    inviteCode: '',
    inviteExpires: new Date(),
    inviteUsed: false,
    role: RoleStrings.Participant,
    pledgeReceived: false,
    pledgeTransactionId: '',
    user: {
      id: 0,
      email: '',
      name: '',
      firstName: '',
      lastName: '',
      avatarUrl: '',
      role: RoleStrings.Participant,
    },
    isActive: true,
  },
};

const defaultExport = { Empty: empty };
export default defaultExport;
