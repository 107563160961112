import { ParticipantView } from '../views/ParticipantView';
import { User } from '../models';
import { CohortUser } from '../models/CohortUser';

export const createParticipantView = (u: User, cohortUser: CohortUser): ParticipantView => {
  return {
    rank: 0,
    score: 0,
    isNew: false,
    ...u,
    CohortUser: cohortUser,
  } as ParticipantView;
};
