import * as contentful from 'contentful';
import { ContentfulClientApi } from 'contentful';

export class ContentStore {
  constructor(
    private readonly client: ContentfulClientApi<undefined> = contentful.createClient({
      space: 'ihnuhnmumsts',
      accessToken: 'NVCi9kZ6FYZZRy7gTQXGm9HDSqBbFDqq3re_KIXA4uE',
    })
  ) {}

  public async readByContentType<T>(
    contentType:
      | 'termsAndConditions'
      | 'dashboard-step'
      | 'scienceSomething'
      | 'info-panel'
      | 'exercise-in-disguise' = 'dashboard-step',
    formatter: (item: any) => T
  ): Promise<T[]> {
    const entries = await this.client.getEntries({ content_type: contentType });
    return entries.items.map(formatter);
  }
}
