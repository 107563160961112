import { ContentStore } from '../api/ContentStore';
import { Step } from 'react-joyride';
import { marked } from 'marked';
import React from 'react';
import { Typography } from '@mui/material';

export class JoyrideContentViewCreator {
  constructor(private readonly store: ContentStore = new ContentStore()) {}

  public async createView(): Promise<Step[]> {
    const items = await this.store.readByContentType('dashboard-step', (item) => {
      return {
        title: String(item.fields['title']),
        target: String(item.fields['target']),
        content: marked(item.fields['content'], { async: false }),
        order: Number(item.fields['order']),
      };
    });

    return items
      .sort((a, b) => a.order - b.order)
      .map((item) => ({
        title: item.title,
        content: <Typography dangerouslySetInnerHTML={{ __html: item.content }} component={'p'}></Typography>,
        target: item.target,
        placement: 'top',
        disableBeacon: true,
        showSkipButton: true,
      }));
  }
}
